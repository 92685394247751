module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cro Metrics Landing Page","description":"Cro Metrics Landing Page","start_url":"/","icon":"src/images/favicon.png","meetOurTeam":[{"firstName":"Chris","lastName":"Neumann","title":"Founder & CEO"},{"firstName":"Ann","lastName":"Devens","title":"VP of Business Development"},{"firstName":"Matthew","lastName":"Gossage","title":"Director of Engineering"},{"firstName":"Jerome","lastName":"Choo","title":"Director of Product Management"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"05f5dfcfbbb95a461db80fc4ca1687b7"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
