import React from 'react';

export type StoreFormData = {
  zip?: string,
  email?: string,
  phone?: string,
  phoneSMS?: string,
};
const defaultFormData = {};
// const defaultFormData = { zip: '94103', email: 'LP7598758619711155delete@crometrics.com', phone: '+18005521234' };

export enum ModalStatus{
  closed,
  open,
  alreadyStarted,
}
type StoreContexValue = {
  modalStatus: ModalStatus,
  setModalStatus: (status: ModalStatus) => void,
  formData: StoreFormData,
  updateFormData: (data: Partial<StoreFormData>) => Partial<StoreFormData>,
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const storeContext = React.createContext({} as StoreContexValue);

/* This is from https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/ */
export default function StoreProvider({ children }: { children: React.ReactNode; }) {
  const [formData, setFormData] = React.useState<StoreFormData>(defaultFormData);
  const [modalStatus, setModalStatus] = React.useState(ModalStatus.closed);

  const updateFormData = (newFormData: Partial<StoreFormData>) => {
    const mergedFormData = {
      ...formData,
      ...newFormData,
    };
    setFormData(mergedFormData);
    return mergedFormData;
  };

  return (
    <storeContext.Provider value={{
      modalStatus,
      setModalStatus,
      formData,
      updateFormData,
    }}>
      {children}
    </storeContext.Provider>
  );
}

export const useGlobalStore = () => React.useContext(storeContext);
