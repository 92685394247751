import { useEffect } from 'react';

const getScrollPosition = () => Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

export const setScrollListener = () => {
  useEffect(() => {
    const toggleVisible = () => {
      const scroll  = getScrollPosition();
      const backToTop = document.querySelector(`#back-to-top`);
      if (scroll > window.innerHeight * .40) {
        backToTop?.classList.add(`scrolled`);
      } else {
        backToTop?.classList.remove(`scrolled`);
      }
    };

    window.addEventListener('scroll', toggleVisible);
  });
};

export const scrollToTop = () => {
  window.location.hash = '';
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};