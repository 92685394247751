import React from 'react';
import styled from 'styled-components';
import ArrowUpSVG from '../../images/back-to-top/arrow-up.inline.svg';
import { colors } from '../../includes/style';
import { scrollToTop } from '../../hooks/setScrollListener';

const BackToTopStyle = styled.button`
  position: fixed;
  padding: 0;
  background: ${colors.blue};
  box-shadow: none;
  border: none;
  opacity: 0;
  transition: all .5s;
  right: 15px;
  bottom: 15px;
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 38px;
  color: #fff;
  border-radius: 50px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  z-index: 20;
  transition: .3s all ease;
  transform: translateY(100px);

  &:hover {
    cursor: pointer;
    background: ${colors.orange};
  }
  
  &.scrolled {
    transform: translateY(0);
    opacity: 1;
  }

  .image {
    height: 12px;
    width: 12px;

    path {
      fill: #fff;
    }
  }
`;

export default function BackToTop() {
  return <BackToTopStyle id='back-to-top' onClick={scrollToTop} type="button" aria-label="Back to top">
    <ArrowUpSVG className="image" />
  </BackToTopStyle>;
}
